import React from "react";

export const H2 = ({ children }) => {
  return (
    <h2 className="mt-4 block text-2xl md:text-4xl font-extrabold tracking-tight text-gray-800">
      {children}
    </h2>
  );
};

export const P = ({ children }) => {
  return <p className="my-8 text-lg text-gray-500 leading-8">{children}</p>;
};

export const UL = ({ children }) => {
  return (
    <ul className="my-4 list-disc text-lg list-inside text-gray-500">
      {children}
    </ul>
  );
};
