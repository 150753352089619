import React from "react";

const PageWrapper = ({ children }) => {
  return (
    <div className="relative px-4 sm:px-6 lg:px-8 pt-10">
      <div className="max-w-screen-lg mx-auto">{children}</div>
    </div>
  );
};

export default PageWrapper;
