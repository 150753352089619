import React from "react";
import StandardPageTemplate from "../../templates/standard-page";
import PageWrapper from "../../components/core/PageWrapper";
import { Link } from "gatsby";
import * as Text from "../../components/text";
import FeatureTable from "../../components/FeatureTable";
import PriceBlockSmall from "../../components/PriceBlockSmall";

const PAGE_PROPS = {
  title: "Cognitive Health Program",
  desc:
    "Learn how to optimize your nutrition and lifestyle to enact the most promising treatment for Alzheimer's disease and dementia in your life, or the life of your loved one.",
};

const CHP_Page = () => {
  return (
    <StandardPageTemplate props={PAGE_PROPS}>
      <PageWrapper>
        <h1>
          <span className="block text-base text-center text-primary font-semibold tracking-wide uppercase">
            Introducing the All-New
          </span>
          <span className="mt-2 block text-4xl md:text-6xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Cognitive Health <br /> Program
          </span>
        </h1>

        <Text.P>
          If you're caring for someone who has Alzheimer's Disease or are trying
          to prevent getting Alzheimer's yourself, understanding what you can do
          to reverse or prevent symptoms is daunting. Our Cognitive Health
          Program has transformed the latest scientific research on fighting &
          preventing cognitive diseases into a specific and
          comprehensive program you can implement yourself, in the comfort and
          privacy of your own home.
        </Text.P>
        <Text.P>
          So much of your health and vitality depends on what you eat and
          how you live, so shouldn't you do everything in your power to ensure
          the choices you make each day lead you to your best possible health
          outcomes?
        </Text.P>
        <Text.H2>What is the Cognitive Health Program?</Text.H2>
        <Text.P>
          Our program is designed to empower you with the methods, tools, and
          information necessary to enact the most promising treatments for
          Alzheimer's disease and dementia in your life, or the life
          of your loved one. Coupled with the available support of the Amos
          Institute's team of highly trained nutrition
          and health specialists, our Cognitive Health Program is the most
          impactful yet most accessible program of its kind. From the first day
          of the program, you will begin making meaningful changes into your
          lifestyle, putting you on the path toward your best possible health
          outcomes.
        </Text.P>
        <Text.P>
          Our Cognitive Health Program is supported by comprehensive{" "}
          <Link to="/what-is-the-bredesen-protocol/">
            evidence based research
          </Link>
          , and is based on the work of world renowned scientists. It has been
          used by people in 13 countries and throughout the United States.
        </Text.P>
        <FeatureTable />

        <Text.P>
          Our Cognitive Health Program is available exclusively online though
          our easy-to-use website. When you sign up online you receive your own
          individualized account which provides access to all of the Amos
          Institute's resources. Your account includes everything you need to
          get started with the program.
        </Text.P>

        <Text.P>
          All of these features come included in your account and are yours to
          take advantage of for as long as you wish. Your account will never
          expire so you can always review and retain access to all of the
          lessons, resources and materials. You can incorporate the program at
          your own pace, using the resources in your account to help you each
          step of the way.
        </Text.P>
        <Text.P>
          Our Cognitive Health Program does not include food, supplements,
          reports, blood work, labs, or the review of any labs or reports, or
          any private meetings, correspondence or consultations. All of those
          personalized services can be purchased separately through our{" "}
          <Link to="/pricing/">Comprehensive Services</Link>.
        </Text.P>
        <Text.H2>What Topics does the Program Cover?</Text.H2>
        <Text.P>
          Our Cognitive Health Program was designed to cover topics in a
          systematic way to maximize your ability to make important and
          healthy changes in your life. The program is broken down into 26
          sections, and each section includes a plethora of videos, resources
          and tools.
        </Text.P>
        <Text.P>Subjects in the program include but are not limited to:</Text.P>
        <div className="max-w-screen-sm px-4">
          <Text.UL>
            <li>The Ketoflex Nutrition Plan</li>
            <li>ApoE4 & Mild Ketosis</li>
            <li>Gut Health</li>
            <li>Physical Exercise</li>
            <li>Brain Exercise</li>
            <li>Epigenetics</li>
            <li>Supplements</li>
            <li>Understanding Organic & SeasonalFoods</li>
            <li>Stress Management & Telomeres</li>
            <li>Your Circadian Clock</li>
            <li>Toxic Body Burden</li>
            <li>Understanding Gluten & Dairy</li>
            <li>Air Quality</li>
            <li>Oral Hygiene</li>
            <li>Hydration</li>
            <li>Time Management</li>
            <li>And so much more...</li>
          </Text.UL>
        </div>
        <Text.H2>How Long is the Program?</Text.H2>
        <Text.P>
          Some clients take 6-8 months to incorporate all of the aspects of the
          program into their daily routine and others do this over a longer
          period of time. The speed at which you watch and learn and adopt the
          Cognitive Health Program strategies is completely up to you and your
          comfort level. And because the lessons always remain on your account,
          you can watch and review them as many times as you wish so you are
          sure to understand them and able to incorporate all of the healthy
          lifestyle components into your daily routine.
        </Text.P>
        <Text.H2>Who Should Sign Up for the Program?</Text.H2>
        <Text.P>
          Our Cognitive Health Program is ideal for anyone who is dealing with
          or trying to prevent Alzheimer's Disease and Dementia as well as
          anyone who is taking care of a loved one with these diseases. Whether
          you are just starting to look for treatment, or if you are already
          working with another practitioner or company, the Amos Institute
          Cognitive Health Program is the best education you will ever receive
          and it will help you maintain and improve your brain health.
        </Text.P>
        <Text.H2>Who Created the Cognitive Health Program?</Text.H2>
        <Text.P>
          Our Cognitive Health Program was created by{" "}
          <Link to="/our-founder/">Amylee Amos PhD, RDN, IFMCP</Link>. Amylee
          trained under Dale Bredesen MD and is one of the only dietitians in
          the United States who is also certified by the Institute for
          Functional Medicine, a new high level achievement for leading medical
          practitioners. Amylee also serves as a guest lecturer at the
          University of Southern California (USC), the University of California
          Los Angeles (UCLA), and Loma Linda University on the subject of the
          Bredesen Protocol. You won’t find a better instructor or a person with
          more experience in the nutritional aspects of the protocol anywhere.
        </Text.P>
        <PriceBlockSmall />
      </PageWrapper>
    </StandardPageTemplate>
  );
};

export default CHP_Page;
