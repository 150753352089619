export const titleAsClass = (title) => {
  return title
    .toLowerCase()
    .split(" ")
    .join("-")
    .split("’")
    .join("")
    .split("?")
    .join("");
};
