import React from "react";
import { Helmet } from "react-helmet";
import "../../css/main.css";
import "../all.sass";

/*
  Notes:
    - This is used by the "standard-page" template
*/

/*
  TODO:
    - Delete Bulma CSS & Remove Bulma Classes
    - Conver to TS
*/

/**
 * Nested or latter components will override duplicate changes.
 */
const Head = ({ title, desc }) => {
  return (
    <Helmet>
      <html lang="en" className="has-navbar-fixed-top" />
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>{title + " | Amos Institute"}</title>
      <meta name="description" content={desc} />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/img/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        href="/img/favicon-32x32.png"
        sizes="32x32"
      />
      <link
        rel="icon"
        type="image/png"
        href="/img/favicon-16x16.png"
        sizes="16x16"
      />
      <meta name="theme-color" content="#fff" />
      <meta property="og:type" content="business.business" />
      <meta property="og:title" content={title + " | Amos Institute"} />
      <meta property="og:description" content={desc} />
      <meta property="og:image" content="/img/og-image.png" />

      <script type="application/ld+json">{`
          {
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "Amos Institute",
            "url": "https://amosinstitute.com",
            "logo": "/img/meta-logo.png",
            "sameAs": [
              "https://www.instagram.com/amosinstitute",
              "https://www.facebook.com/theamosinstitute",
              "https://twitter.com/amosinstitute"
            ]
          }
          `}</script>

      <meta
        name="google-site-verification"
        content="u0qupTgKgjMXcvDkgYwxnjQAP7yIjsoUfprHcdvcHOs"
      />
    </Helmet>
  );
};

export default Head;
