import React from "react";
import { Link } from "gatsby";

import chpIcon from "../img/pricing/chp-icon.svg";
import chpBg from "../img/pricing/chp-bg.svg";

const PriceBlockSmall = () => {
  return (
    <>
      <div className="max-w-xl mx-auto shadow-lg mb-14 mt-10">
        <div
          style={{
            backgroundImage: `url(${chpBg})`,
            backgroundColor: `#4665B0`,
            backgroundPosition: `center top`,
            backgroundSize: `150%`,
          }}
          className="rounded-lg bg-cover bg-no-repeat w-full"
        >
          <div className="pt-8 pb-8 px-6">
            <span className="text-white text-4xl font-extrabold block">
              The Cognitive Health Program
            </span>
            <span className="text-white block pb-4">
              Our flagship program is designed to help you fight Dementia,
              reverse Alzheimer's Disease and maintain your very best cognitive
              healthspan.
            </span>
            <span className="font-black text-7xl text-white inline-block">
              $365
            </span>
            <img
              src={chpIcon}
              role="presentation"
              alt=""
              className="hidden lg:inline-block ml-4 lg:ml-8 xl:ml-10 -mt-14 pl-10 pt-4"
            />
            <span className="text-white block -mt-0 md:-mt-4 text-sm mb-10">
              One-Time Payment
            </span>
            <Link
              className="block w-auto md:w-8/12 text-white hover:text-white"
              to={
                typeof window !== `undefined`
                  ? `/signup/${window.location.search}`
                  : `/signup/`
              }
            >
              <button
                className={`bg-primary hover:bg-purple-500 text-white font-bold py-2 px-6 rounded w-auto md:w-auto`}
              >
                Sign Up
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default PriceBlockSmall;
