import React from "react";

import LessonsIcon from "Images/homepage/feature-icons/v2/lessons.svg";
import OfficeHoursIcon from "Images/homepage/feature-icons/v2/office-hours.svg";
import ResourcesIcon from "Images/homepage/feature-icons/v2/resources.svg";
import RDNIcon from "Images/homepage/feature-icons/v2/rdn.svg";
import UIIcon from "Images/homepage/feature-icons/v2/ui.svg";
import MealPlansIcon from "Images/homepage/feature-icons/v2/meal-plans.svg";

const FeatureTable = (props) => {
  return (
    <div className="relative bg-white py-2 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
          What Does the Program Include?
        </p>
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            <div className="pt-6">
              <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                <div className="-mt-6">
                  <div>
                    <span className="inline-flex items-center rounded-3xl w-14 justify-center shadow-lg">
                      <img className="rounded-3xl" src={UIIcon} alt="" />
                    </span>
                  </div>
                  <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                    Simple Online Interface
                  </h3>
                  <p className="mt-5 text-base text-gray-500">
                    Access all aspects of your program online in one convenient
                    location.
                  </p>
                </div>
              </div>
            </div>

            <div className="pt-6">
              <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                <div className="-mt-6">
                  <div>
                    <span className="inline-flex items-center rounded-3xl w-14 justify-center shadow-lg">
                      <img className="rounded-3xl" src={LessonsIcon} alt="" />
                    </span>
                  </div>
                  <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                    Engaging Video Lessons
                  </h3>
                  <p className="mt-5 text-base text-gray-500">
                    25+ engaging lessons to walk you through every aspect of the
                    program.
                  </p>
                </div>
              </div>
            </div>

            <div className="pt-6">
              <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                <div className="-mt-6">
                  <div>
                    <span className="inline-flex items-center rounded-3xl w-14 justify-center shadow-lg">
                      <img
                        className="rounded-3xl"
                        src={OfficeHoursIcon}
                        alt=""
                      />
                    </span>
                  </div>
                  <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                    Office Hours
                  </h3>
                  <p className="mt-5 text-base text-gray-500">
                    Ask questions and watch a weekly live video Q&A with Amylee
                    Amos.
                  </p>
                </div>
              </div>
            </div>

            <div className="pt-6">
              <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                <div className="-mt-6">
                  <div>
                    <span className="inline-flex items-center rounded-3xl w-14 justify-center shadow-lg">
                      <img className="rounded-3xl" src={MealPlansIcon} alt="" />
                    </span>
                  </div>
                  <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                    Meal Plans & Recipes
                  </h3>
                  <p className="mt-5 text-base text-gray-500">
                    Access to RDN crafted meal plans & recipes for any occasion.
                    <br />
                    <br />
                  </p>
                </div>
              </div>
            </div>

            <div className="pt-6">
              <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                <div className="-mt-6">
                  <div>
                    <span className="inline-flex items-center rounded-3xl w-14 justify-center shadow-lg">
                      <img className="rounded-3xl" src={ResourcesIcon} alt="" />
                    </span>
                  </div>
                  <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                    Helpful Tools & Resources
                  </h3>
                  <p className="mt-5 text-base text-gray-500">
                    Curated tools, resources and recommended products from the
                    Amos Institute team.
                    <br />
                    <br />
                  </p>
                </div>
              </div>
            </div>

            <div className="pt-6">
              <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                <div className="-mt-6">
                  <div>
                    <span className="inline-flex items-center rounded-3xl w-14 justify-center shadow-lg">
                      <img className="rounded-3xl" src={RDNIcon} alt="" />
                    </span>
                  </div>
                  <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                    Personalized Services
                  </h3>
                  <p className="mt-5 text-base text-gray-500">
                    Access to dietitian appointments, lab analysis, supplement
                    guidance, and personalized meal-plans.<small>*</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <small className="text-gray-500 block pt-10 pb-6">
            *Optional personalized services are available for an additional
            cost.
          </small>
        </div>
      </div>
    </div>
  );
};

export default FeatureTable;
