import React from "react";
import Head from "../components/core/Head";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { titleAsClass } from "../utils/transformations";

const StandardPageTemplate = ({ children, props }) => {
  const { title, desc } = props;
  return (
    <>
      <Head title={title} desc={desc} />
      <Navbar />
      <article id="standard-page-content" className={titleAsClass(title)}>
        {children}
      </article>
      <Footer />
    </>
  );
};

export default StandardPageTemplate;
